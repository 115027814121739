import 'jquery'; // Import jQuery
import React, { useEffect,useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'owl.carousel';
import test1 from "../assets/test/author-image-1.jpg";
import test2 from "../assets/test/author-image-2.jpg";
import { FaStar,FaQuoteRight, FaCircle } from "react-icons/fa";
const Map = () => {
  const owlCarouselRef = useRef(null);

  useEffect(() => {
    // Ensure $ is available when the component mounts
    const $ = window.$;

    // Initialize Owl Carousel
    owlCarouselRef.current = $('#carousel2').owlCarousel({
      loop: true,
      margin: 10,
      nav: true,
      touchDrag: true,
      autoplay: true,
      autoplayTimeout: 2000,
      navText: [
        '<i className="bi bi-arrow-left"></i>',
        '<i className="bi bi-arrow-right"></i>'
      ],
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 1
        },
        1000: {
          items: 2
        }
      }
    });
  }, []);
  const nextSlide = () => {
    owlCarouselRef.current.trigger('next.owl.carousel');
  };

  const prevSlide = () => {
    owlCarouselRef.current.trigger('prev.owl.carousel');
  };
  return (
    <div  className="containerFluid fadeInUp">
      <div className="container" style={{ paddingTop: "30px" }}>
        <div id="carousel2" className="owl-carousel" >
          {/* Add your card components here */}
          <div className="testimonaly" style={{ color: "#fff" }}>
           <div className='innerBox'>
            <div className='innerDiv'>
              <div className='testLogo'>
                <FaQuoteRight />
              </div>
              <div className='imgDivsss'>
              <img src={test1} alt='test' />
              </div>
              <div>

              </div>
              <div className='testimonalyData'>
                <h5 className='testimonalyName'>Noah Oliver</h5>
                <p className='testimonlyDes' >Cruising To The Cyclades</p>
              </div>
              <div className='ratediv'>
                <div className='rateStart' style={{fontSize:"15px"}}>
                  <FaStar className='me-1' /><FaStar className='me-1' /><FaStar className='me-1' /><FaStar className='me-1' /><FaStar className='me-1' />
                </div>
              </div>
              <div className="textData">The holiday of a life time our gulet adventure in the Greek Islands was without doubt our favourite trip.</div>
            </div>
           </div>

          </div>
          <div className="testimonaly" style={{ color: "#fff" }}>
          <div className='innerBox'>
            <div className='innerDiv'>
              <div className='testLogo'>
                <FaQuoteRight />
              </div>
              <div className='imgDivsss'>
              <img src={test2} alt='test' />
              </div>
              <div>

              </div>
              <div className='testimonalyData'>
                <h5 className='testimonalyName'>Chris Bannan</h5>
                <p className='testimonlyDes' >Netherland, 2019</p>
              </div>
              <div className='ratediv'>
                <div className='rateStart' style={{fontSize:"15px"}}>
                  <FaStar className='me-1' /><FaStar className='me-1' /><FaStar className='me-1' /><FaStar className='me-1' /><FaStar className='me-1' />
                </div>
              </div>
              <div className="textData">The holiday of a life time our gulet adventure in the Greek Islands was without doubt our favourite trip.</div>
            </div>
           </div>

          </div>
          {/* Add more cards as needed */}
        </div>
        <div className="carousel-controls">
          <button className="btn " onClick={prevSlide}>
            <FaCircle style={{color:"#cfd6e0",fontSize:"10px"}} />
          </button>
          <button className="btn " onClick={prevSlide}>
            <FaCircle style={{color:"#cfd6e0",fontSize:"10px"}} />
          </button>
          <button className="btn " onClick={nextSlide}>
            <FaCircle style={{color:"#cfd6e0",fontSize:"10px"}} />
          </button>
          <button className="btn " onClick={nextSlide}>
            <FaCircle style={{color:"#cfd6e0",fontSize:"10px"}} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Map;

import 'jquery'; // Import jQuery
import React, { useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'owl.carousel';
import test1 from "../assets/featured-image-1.jpg";
import test2 from "../assets/featured-image-2.jpg";
import test3 from "../assets/featured-image-3.jpg";
import { FaArrowAltCircleRight, FaFly, FaCircle, FaShapes, FaShip } from "react-icons/fa";
// import { FaQuoteRight, FaStar } from "react-icons/fa";
const WhatWeDo = () => {
    const owlCarouselRef = useRef(null);
    useEffect(() => {
        // Ensure $ is available when the component mounts
        const $ = window.$;

        // Initialize Owl Carousel
        owlCarouselRef.current = $('#carousel4').owlCarousel({
            loop: true,
            margin: 10,
            nav: true,
            touchDrag: true,
            autoplay: true, // Enable autoplay
            autoplayTimeout: 2000, // Set autoplay timeout in milliseconds (e.g., 3000 for 3 seconds)

            navText: [
                '<FaStar />', // Left arrow icon, you can replace this with your own HTML or icon class
                '<i className="bi bi-arrow-right"></i>' // Right arrow icon, you can replace this with your own HTML or icon class
            ],
            responsive: {
                0: {
                    items: 1
                },
                600: {
                    items: 1
                },
                1000: {
                    items: 1
                }
            }
        });
    }, []); // Empty dependency array ensures the effect runs once on mount
    const nextSlide = () => {

        owlCarouselRef.current.trigger('next.owl.carousel');
    };

    const prevSlide = () => {
        owlCarouselRef.current.trigger('prev.owl.carousel');
    };
    return (
        <div className=" fadeInUp fadeInLeft">
            <div className="container">
                <div className="owl-carousel" id='carousel4'>
                    {/* Add your card components here */}
                    <div class="offer-block">
                        <div class="inner-box">
                            <div class="icon-box">
                                <span class="flaticon"><FaShip style={{fontSize:"90px"}}/></span>
                            </div>
                            <h4>We Provide <br />Some Best Offers</h4>
                            <div class="subtitle">For Newly Married Couples</div>
                            <div class="offer-price">
                                <span class="percent">40<em>%</em></span>
                                <span class="txt">Offer</span>
                            </div>
                            <div className="btnBookNow  btnBookNow3"> Book Now

                            </div>
                        </div>
                    </div>
                    

                </div>
                <div className="carousel-controls">
                    <button className="btn " onClick={prevSlide}>
                        <FaCircle style={{ color: "#cccccc", fontSize: "10px" }} />
                    </button>
                    <button className="btn " onClick={prevSlide}>
                        <FaCircle style={{ color: "#cccccc", fontSize: "10px" }} />
                    </button>
                    <button className="btn " onClick={nextSlide}>
                        <FaCircle style={{ color: "#cccccc", fontSize: "10px" }} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default WhatWeDo;

import React, { Fragment, useState } from "react";
import classes from "./Navbar.module.css";
import { AiOutlineCaretDown } from "react-icons/ai";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import logo from "../../assets/sticky-logo.png";
import { FaFacebook, FaHamburger, FaInstagram, FaSearch, FaTwitter, FaYoutube } from "react-icons/fa";



const Navbar = () => {

  const [HamburgerIsOpen, SetHamburgerIsOpen] = useState(false);
  const [showProductDropDown, setShowProductDropDown] = useState(false);
  const [showResourcesDropDown, setShowResourcesDropDown] = useState(false);
  const [showComDropDown, setShowComDropDown] = useState(false);
  const [showPageDropDown, setShowPageropDown] = useState(false);
  const [showBlogDropDown, setShowBlogropDown] = useState(false);
  const [showSubDropDown, setShowSubropDown] = useState(false);

  const hamburgerClickHandler = () => {
    if (!HamburgerIsOpen) {
      document
        .getElementById("navbar__mobile__div")
        .classList.add("slide_from_top");
      document
        .getElementById("navbar__mobile__div")
        .classList.remove("slide-from-bottom");
    } else {
      document
        .getElementById("navbar__mobile__div")
        .classList.add("slide-from-bottom");
      document
        .getElementById("navbar__mobile__div")
        .classList.remove("slide_from_top");
    }
    SetHamburgerIsOpen(!HamburgerIsOpen);
  };

  const toggleProductDropdown = (e) => {
    e.preventDefault();
    setShowProductDropDown(!showProductDropDown);
    setShowResourcesDropDown(false);
    setShowComDropDown(false);
    setShowPageropDown(false);
    setShowBlogropDown(false);
    setShowSubropDown(false);
  };
  const toggleResourceDropdown = (e) => {
    e.preventDefault();
    setShowResourcesDropDown(!showResourcesDropDown);
    setShowProductDropDown(false);
    setShowComDropDown(false);
    setShowPageropDown(false);
    setShowBlogropDown(false);
    setShowSubropDown(false);
  };
  const toggleComDropdown = (e) => {
    e.preventDefault();
    setShowComDropDown(!showComDropDown);
    setShowResourcesDropDown(false);
    setShowProductDropDown(false);
    setShowPageropDown(false);
    setShowBlogropDown(false);
    setShowSubropDown(false);
  };
  const togglepageDropdown = (e) => {
    e.preventDefault();
    setShowPageropDown(!showPageDropDown);
    setShowResourcesDropDown(false);
    setShowProductDropDown(false);
    setShowComDropDown(false);
    setShowBlogropDown(false);
    setShowSubropDown(false);
  };
  const toggleblogDropdown = (e) => {
    e.preventDefault();
    setShowBlogropDown(!showBlogDropDown);
    setShowResourcesDropDown(false);
    setShowProductDropDown(false);
    setShowComDropDown(false);
    setShowPageropDown(false);
    setShowSubropDown(false);
  };

  const togglesubDropdown = (e) => {
    e.preventDefault();
    setShowSubropDown(!showSubDropDown);
    setShowResourcesDropDown(false);
    // setShowProductDropDown(false);
    setShowComDropDown(false);
    // setShowPageropDown(false);
    setShowBlogropDown(false);
  };
  return (

    <div className={`${classes.navbar__div} `}>
      <div className={`${classes.navbar__div__logo} fadeInUp`}>
        <a href="/">
          <img src={logo}
            className="elementor-animation-buzz"
            alt="website_logo"
          />
        </a>
      </div>
      <div className={`${classes.navbar__div__links} fadeInUp`} style={{ marginLeft: "120px" }}>
        <ul className={classes.navbar__div__links_ul}>
          <li
            className={
              classes.navbar__div__link +
              " " +
              classes.navbar__div__link__has_dropdown
            }
          >
            <Link
              to="#"
              className={classes.nav_barlink_dropdown}
              onMouseOver={toggleProductDropdown}
              style={{ color: "#d5ae82" }}
            >
              Home

            </Link>
            {showProductDropDown ? (
              <Fragment>
                <div
                  className={classes.navbar_backdrop}
                  onMouseOver={() => {

                    setShowProductDropDown(!showProductDropDown);
                  }}
                ></div>
                <div className={(classes.productDropdown) + '  ' + (classes.borderRadiusNavbarDropdown)}>
                  <div className="">
                    <div className="">
                      <div className="">
                        <div className={classes.product_text}>
                          <ul className="" style={{ listStyle: "none" }}>
                            <li style={{ marginTop: "20px" }}>

                              <Link to="/" className="navBarLi" onClick={() => setShowProductDropDown(!showProductDropDown)}>Home 01 - Yacht Tour</Link>
                            </li>
                            <li>

                              <Link to="#" onClick={() => setShowProductDropDown(!showProductDropDown)}>Home 02 - Yacht Rental</Link>
                            </li>
                            <li>

                              <Link to="#" onClick={() => setShowProductDropDown(!showProductDropDown)}>Home 03 - Yacht Rental</Link>
                            </li>


                            <li
                              className={
                                classes.navbar__div__link_sub +
                                " " +
                                classes.navbar__div__link__has_dropdown
                              }
                            >
                              <Link
                                to="#"
                                className={classes.nav_barlink_dropdown + " " + classes.sublist}
                                onClick={togglesubDropdown}
                              >
                                Header Styles
                              </Link>
                              {showSubDropDown ? (
                                <Fragment>
                                  <div
                                    className={classes.navbar_backdrop}
                                    onClick={() => {
                                      setShowSubropDown(!showSubDropDown);
                                    }}
                                  ></div>
                                  <div className={(classes.productDropdownsub) + '  ' + (classes.borderRadiusNavbarDropdown)}>
                                    <div className="">
                                      <div className="">
                                        <div className="">
                                          <div className={classes.product_text}>
                                            <ul className="" style={{ listStyle: "none" }}>
                                              <li style={{ marginTop: "20px" }}>

                                                <Link to="#" className="navBarLi" onClick={() => setShowProductDropDown(!showProductDropDown)}>Header Style 01</Link>
                                              </li>
                                              <li>

                                                <Link to="#" onClick={() => setShowProductDropDown(!showProductDropDown)}>Header Style 03</Link>
                                              </li>
                                              <li>

                                                <Link to="#" onClick={() => setShowProductDropDown(!showProductDropDown)}>Header Style 03</Link>
                                              </li>

                                            </ul>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Fragment>
                              ) : (
                                ""
                              )}
                            </li>
                          </ul>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            ) : (
              ""
            )}
          </li>
          <li
            className={
              classes.navbar__div__link +
              " " +
              classes.navbar__div__link__has_dropdown
            }
          >
            <Link
              to="#"
              className={classes.nav_barlink_dropdown}
              onMouseOver={toggleResourceDropdown}
            >
              Yacht Rent

            </Link>
            {showResourcesDropDown ? (
              <Fragment>
                <div
                  className={classes.navbar_backdrop}
                  onMouseOver={() => {
                    setShowResourcesDropDown(!showResourcesDropDown);
                  }}
                ></div>
                <div className={(classes.productDropdown) + '  ' + (classes.borderRadiusNavbarDropdown)}>
                  <div className="">
                    <div className="">
                      <div className="">
                        <div className={classes.product_text}>
                          <ul className="" style={{ listStyle: "none" }}>
                            <li style={{ marginTop: "20px" }}>

                              <Link to="/" className="navBarLi" onClick={() => setShowProductDropDown(!showProductDropDown)}>Yacht Rent & Buy</Link>
                            </li>
                            <li>

                              <Link to="#" onClick={() => setShowProductDropDown(!showProductDropDown)}>Sotavento</Link>
                            </li>
                            <li>

                              <Link to="#" onClick={() => setShowProductDropDown(!showProductDropDown)}>West Port z12</Link>
                            </li>
                            <li>

                              <Link to="#" onClick={() => setShowProductDropDown(!showProductDropDown)} >Obsesion</Link>
                            </li>
                          </ul>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            ) : (
              ""
            )}
          </li>
          <li
            className={
              classes.navbar__div__link +
              " " +
              classes.navbar__div__link__has_dropdown
            }
          >
            <Link
              to="#"
              className={classes.nav_barlink_dropdown}
              onMouseOver={toggleComDropdown}
            >
              Yacht Tour

            </Link>
            {showComDropDown ? (
              <Fragment>
                <div
                  className={classes.navbar_backdrop}
                  onMouseOver={() => {

                    setShowComDropDown(!showComDropDown);
                  }}
                ></div>
                <div className={(classes.productDropdown) + '  ' + (classes.borderRadiusNavbarDropdown)}>
                  <div className="">
                    <div className="">
                      <div className="">
                        <div className={classes.product_text}>
                          <ul className="" style={{ listStyle: "none" }}>
                            <li style={{ marginTop: "20px" }}>

                              <Link to="#" className="navBarLi" onClick={() => setShowProductDropDown(!showProductDropDown)}>Tour Style 01</Link>
                            </li>
                            <li>

                              <Link to="#" onClick={() => setShowProductDropDown(!showProductDropDown)}>Tour Style 02</Link>
                            </li>
                            <li>

                              <Link to="#" onClick={() => setShowProductDropDown(!showProductDropDown)}>Tour Style 03</Link>
                            </li>
                            <li>

                              <Link to="#" onClick={() => setShowProductDropDown(!showProductDropDown)} >Tour Style 04</Link>
                            </li>
                            <li>

                              <Link to="#" onClick={() => setShowProductDropDown(!showProductDropDown)} >Single Tour</Link>
                            </li>
                          </ul>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            ) : (
              ""
            )}
          </li>
          <li className={`${classes.navbar__div__link} elementor-animation-buzz`}>
            <Link to="#" onMouseOver={() => {
              setShowResourcesDropDown(null);
              setShowPageropDown(null);
              setShowComDropDown(null);
            }}>Destinatons</Link>
          </li>
          <li
            className={
              classes.navbar__div__link +
              " " +
              classes.navbar__div__link__has_dropdown
            }
          >
            <Link
              to="#"
              className={classes.nav_barlink_dropdown}
              onMouseOver={togglepageDropdown}
            >
              Pages

            </Link>
            {showPageDropDown ? (
              <Fragment>
                <div
                  className={classes.navbar_backdrop}
                  onMouseOver={() => {

                    setShowPageropDown(!showPageDropDown);
                  }}
                ></div>
                <div className={(classes.productDropdown) + '  ' + (classes.borderRadiusNavbarDropdown)}>
                  <div className="">
                    <div className="">
                      <div className="">
                        <div className={classes.product_text}>
                          <ul className="" style={{ listStyle: "none" }}>
                            <li style={{ marginTop: "20px" }}>

                              <Link to="#" className="navBarLi" onClick={() => setShowProductDropDown(!showProductDropDown)}>About Us</Link>
                            </li>
                            <li>

                              <Link to="#" onClick={() => setShowProductDropDown(!showProductDropDown)}>Our Services</Link>
                            </li>
                            <li
                              className={
                                classes.navbar__div__link_sub +
                                " " +
                                classes.navbar__div__link__has_dropdown
                              }
                            >
                              <Link
                                to="#"
                                className={classes.nav_barlink_dropdown + " " + classes.sublist}
                                onClick={togglesubDropdown}
                              >
                                Portfolio
                              </Link>
                              {showSubDropDown ? (
                                <Fragment>
                                  <div
                                    className={classes.navbar_backdrop}
                                    onClick={() => {
                                      setShowSubropDown(!showSubDropDown);
                                    }}
                                  ></div>
                                  <div className={(classes.productDropdownsubPage) + '  ' + (classes.borderRadiusNavbarDropdown)}>
                                    <div className="">
                                      <div className="">
                                        <div className="">
                                          <div className={classes.product_text}>
                                            <ul className="" style={{ listStyle: "none" }}>
                                              <li style={{ marginTop: "20px" }}>

                                                <Link to="#" className="navBarLi" onClick={() => setShowProductDropDown(!showProductDropDown)}>1 Column</Link>
                                              </li>
                                              <li>

                                                <Link to="#" onClick={() => setShowProductDropDown(!showProductDropDown)}>2 Column</Link>
                                              </li>
                                              <li>

                                                <Link to="#" onClick={() => setShowProductDropDown(!showProductDropDown)}>3 Column</Link>
                                              </li>
                                              <li>

                                                <Link to="#" onClick={() => setShowProductDropDown(!showProductDropDown)}>Masonry</Link>
                                              </li>

                                            </ul>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Fragment>
                              ) : (
                                ""
                              )}
                            </li>
                            <li>

                              <Link to="#" onClick={() => setShowProductDropDown(!showProductDropDown)} >Contact Us</Link>
                            </li>
                            <li>

                              <Link to="#" onClick={() => setShowProductDropDown(!showProductDropDown)} >Coming Soon</Link>
                            </li>
                          </ul>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            ) : (
              ""
            )}
          </li>
          <li
            className={
              classes.navbar__div__link +
              " " +
              classes.navbar__div__link__has_dropdown
            }
          >
            <Link
              to="#"
              className={classes.nav_barlink_dropdown}
              onMouseOver={toggleblogDropdown}
            >
              Blog

            </Link>
            {showBlogDropDown ? (
              <Fragment>
                <div
                  className={classes.navbar_backdrop}
                  onMouseOver={() => {

                    setShowBlogropDown(!showBlogDropDown);
                  }}
                ></div>
                <div className={(classes.productDropdown) + '  ' + (classes.borderRadiusNavbarDropdown)}>
                  <div className="">
                    <div className="">
                      <div className="">
                        <div className={classes.product_text}>
                          <ul className="" style={{ listStyle: "none" }}>
                            <li style={{ marginTop: "20px" }}>

                              <Link to="#" className="navBarLi" onClick={() => setShowProductDropDown(!showProductDropDown)}>Blog Grid View</Link>
                            </li>
                            <li>

                              <Link to="#" onClick={() => setShowProductDropDown(!showProductDropDown)}>Blog List View</Link>
                            </li>
                            <li>

                              <Link to="#" onClick={() => setShowProductDropDown(!showProductDropDown)}>Large Image W/S</Link>
                            </li>
                            <li>

                              <Link to="#" onClick={() => setShowProductDropDown(!showProductDropDown)} >Single Post</Link>
                            </li>
                          </ul>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            ) : (
              ""
            )}
          </li>
          <li className={`${classes.navbar__div__link} elementor-animation-buzz`}>
            <Link to="#"
              onMouseOver={() => {

                setShowBlogropDown(null);
              }}
            >Contact</Link>
          </li>

          <li className={`${classes.navbar__div__link} elementor-animation-buzz`}>
            <Link to="#" className={classes.nav_barlink_dropdown}>
              <span><FaSearch style={{ color: "#b5c0cf" }} /></span>
            </Link>

          </li>

          <li className={`${classes.navbar__div__link} elementor-animation-buzz`}>
            <Link to="#" className={classes.nav_barlink_dropdown}>
              <div className="btnBookNow"> Book Now

              </div>
            </Link>

          </li>

        </ul>
      </div>

      {!HamburgerIsOpen ? (
        <FaHamburger
          onClick={hamburgerClickHandler}
          className={classes.navbar__hamburger}
        />
      ) : (
        <AiOutlineClose
          onClick={hamburgerClickHandler}
          className={classes.navbar__hamburger}
        />
      )}

      <div id="navbar__mobile__div" className={classes.navbar__mobile}>
        <ul className={classes.moble_bor}>
          <li
            className={
              classes.navbar__div__link +
              " " +
              classes.navbar__div__link__has_dropdown
            }
          >
            <Link
              to="#"
              className={classes.nav_barlink_dropdown}
              onClick={toggleProductDropdown}
            >
              Home
              <AiOutlineCaretDown
                className={classes.navbar__div__link_dropdown_iconMob}
              />
            </Link>
            {showProductDropDown ? (
              <Fragment>
                <div
                  onClick={() => {
                    setShowProductDropDown(!showProductDropDown);
                  }}
                ></div>
                <div>
                  <ul className={classes.footer_ul} style={{ marginLeft: "20px" }}>
                    <li className={classes.marLiMoblie}>
                      <Link to="#" onClick={hamburgerClickHandler} >Home 01 - Yacht Tour</Link>
                    </li>
                    <li className={classes.marLiMoblie}>
                      <Link to="#" onClick={hamburgerClickHandler}>Home 02 - Yacht Rental</Link>
                    </li>
                    <li className={classes.marLiMoblie}>
                      <Link to="#" onClick={hamburgerClickHandler}>Home 03 - Water Sports</Link>
                    </li>
                    <li
                      className={
                        classes.navbar__div__link +
                        " " +
                        classes.navbar__div__link__has_dropdown
                      }
                    >
                      <Link
                        to="#"
                        className={classes.nav_barlink_dropdown}
                        onClick={togglesubDropdown}
                      >
                        Header Styles
                        <AiOutlineCaretDown
                          className={classes.navbar__div__link_dropdown_iconMob}
                        />
                      </Link>
                      {showSubDropDown ? (
                        <Fragment>
                          <div
                            onClick={() => {
                              setShowSubropDown(!showSubDropDown);
                            }}
                          ></div>
                          <div>
                            <ul className={classes.footer_ul} style={{ marginLeft: "20px" }}>
                              <li className={classes.marLiMoblie}>
                                <Link to="#" onClick={hamburgerClickHandler} >Header Style 01</Link>
                              </li>
                              <li className={classes.marLiMoblie}>
                                <Link to="#" onClick={hamburgerClickHandler}>Header Style 02</Link>
                              </li>
                              <li className={classes.marLiMoblie}>
                                <Link to="#" onClick={hamburgerClickHandler}>Header Style 03</Link>
                              </li>
                            </ul>
                          </div>
                        </Fragment>
                      ) : (
                        ""
                      )}
                    </li>
                  </ul>
                </div>
              </Fragment>
            ) : (
              ""
            )}
          </li>

          <li
            className={
              classes.navbar__div__link +
              " " +
              classes.navbar__div__link__has_dropdown
            }
          >
            <Link
              to="#"
              className={classes.nav_barlink_dropdown}
              onClick={toggleResourceDropdown}
            >
              Yacht Rent
              <AiOutlineCaretDown
                className={classes.navbar__div__link_dropdown_iconMob}
              />
            </Link>
            {showResourcesDropDown ? (
              <Fragment>
                <div
                  onClick={() => {
                    setShowResourcesDropDown(!showResourcesDropDown);
                  }}
                ></div>
                <div>
                  <ul className={classes.footer_ul} style={{ marginLeft: "20px" }}>
                    <li className={classes.marLiMoblie}>
                      <Link to="#" onClick={hamburgerClickHandler} >Yacht Rent & Buy</Link>
                    </li>
                    <li className={classes.marLiMoblie}>
                      <Link to="#" onClick={hamburgerClickHandler}>Sotavento</Link>
                    </li>
                    <li className={classes.marLiMoblie}>
                      <Link to="#" onClick={hamburgerClickHandler}>West Port z12</Link>
                    </li>
                    <li className={classes.marLiMoblie}>
                      <Link to="#" onClick={hamburgerClickHandler}>Obsesion</Link>
                    </li>
                  </ul>
                </div>
              </Fragment>
            ) : (
              ""
            )}
          </li>
          <li
            className={
              classes.navbar__div__link +
              " " +
              classes.navbar__div__link__has_dropdown
            }
          >
            <Link
              to="#"
              className={classes.nav_barlink_dropdown}
              onClick={toggleComDropdown}
            >
              Yacht Tour
              <AiOutlineCaretDown
                className={classes.navbar__div__link_dropdown_iconMob}
              />
            </Link>
            {showComDropDown ? (
              <Fragment>
                <div
                  onClick={() => {
                    setShowComDropDown(!showComDropDown);
                  }}
                ></div>
                <div>
                  <ul className={classes.footer_ul} style={{ marginLeft: "20px" }}>
                    <li className={classes.marLiMoblie}>
                      <Link to="#" onClick={hamburgerClickHandler} >Tour Style 01</Link>
                    </li>
                    <li className={classes.marLiMoblie}>
                      <Link to="#" onClick={hamburgerClickHandler}>Tour Style 02</Link>
                    </li>
                    <li className={classes.marLiMoblie}>
                      <Link to="#" onClick={hamburgerClickHandler}>Tour Style 03</Link>
                    </li>
                    <li className={classes.marLiMoblie}>
                      <Link to="#" onClick={hamburgerClickHandler}>Tour Style 04</Link>
                    </li>
                    <li className={classes.marLiMoblie}>
                      <Link to="#" onClick={hamburgerClickHandler}>Single Tour</Link>
                    </li>
                  </ul>
                </div>
              </Fragment>
            ) : (
              ""
            )}
          </li>
          <li className={classes.navbar__div__link}>
            <Link to="#" onClick={hamburgerClickHandler} className={classes.nav_barlink_dropdown}>
              Destinatons
            </Link>
          </li>
          <li
            className={
              classes.navbar__div__link +
              " " +
              classes.navbar__div__link__has_dropdown
            }
          >
            <Link
              to="#"
              className={classes.nav_barlink_dropdown}
              onClick={togglepageDropdown}
            >
              Pages
              <AiOutlineCaretDown
                className={classes.navbar__div__link_dropdown_iconMob}
              />
            </Link>
            {showPageDropDown ? (
              <Fragment>
                <div
                  onClick={() => {
                    setShowPageropDown(!showPageDropDown);
                  }}
                ></div>
                <div>
                  <ul className={classes.footer_ul} style={{ marginLeft: "20px" }}>
                    <li className={classes.marLiMoblie}>
                      <Link to="#" onClick={hamburgerClickHandler} >About Us</Link>
                    </li>
                    <li className={classes.marLiMoblie}>
                      <Link to="#" onClick={hamburgerClickHandler}>Our Services</Link>
                    </li>
                    <li
                      className={
                        classes.navbar__div__link +
                        " " +
                        classes.navbar__div__link__has_dropdown
                      }
                    >
                      <Link
                        to="#"
                        className={classes.nav_barlink_dropdown}
                        onClick={togglesubDropdown}
                      >
                        Portfolio
                        <AiOutlineCaretDown
                          className={classes.navbar__div__link_dropdown_iconMob}
                        />
                      </Link>
                      {showSubDropDown ? (
                        <Fragment>
                          <div
                            onClick={() => {
                              setShowSubropDown(!showSubDropDown);
                            }}
                          ></div>
                          <div>
                            <ul className={classes.footer_ul} style={{ marginLeft: "20px" }}>
                              <li className={classes.marLiMoblie}>
                                <Link to="#" onClick={hamburgerClickHandler} >1 Column</Link>
                              </li>
                              <li className={classes.marLiMoblie}>
                                <Link to="#" onClick={hamburgerClickHandler}>2 Column</Link>
                              </li>
                              <li className={classes.marLiMoblie}>
                                <Link to="#" onClick={hamburgerClickHandler}>3 Column</Link>
                              </li>
                            </ul>
                          </div>
                        </Fragment>
                      ) : (
                        ""
                      )}
                    </li>
                    <li className={classes.marLiMoblie}>
                      <Link to="#" onClick={hamburgerClickHandler}>Contact Us</Link>
                    </li>
                    <li className={classes.marLiMoblie}>
                      <Link to="#" onClick={hamburgerClickHandler}>Coming Soon</Link>
                    </li>
                  </ul>
                </div>
              </Fragment>
            ) : (
              ""
            )}
          </li>
          <li
            className={
              classes.navbar__div__link +
              " " +
              classes.navbar__div__link__has_dropdown
            }
          >
            <Link
              to="#"
              className={classes.nav_barlink_dropdown}
              onClick={toggleblogDropdown}
            >
              Blog
              <AiOutlineCaretDown
                className={classes.navbar__div__link_dropdown_iconMob}
              />
            </Link>
            {showBlogDropDown ? (
              <Fragment>
                <div
                  onClick={() => {
                    setShowBlogropDown(!showBlogDropDown);
                  }}
                ></div>
                <div>
                  <ul className={classes.footer_ul} style={{ marginLeft: "20px" }}>
                    <li className={classes.marLiMoblie}>
                      <Link to="#" onClick={hamburgerClickHandler} >Blog Grid View</Link>
                    </li>
                    <li className={classes.marLiMoblie}>
                      <Link to="#" onClick={hamburgerClickHandler}>Blog List View</Link>
                    </li>
                    <li className={classes.marLiMoblie}>
                      <Link to="#" onClick={hamburgerClickHandler}>Large Image W/S</Link>
                    </li>
                    <li className={classes.marLiMoblie}>
                      <Link to="#" onClick={hamburgerClickHandler}>Single Post</Link>
                    </li>

                  </ul>
                </div>
              </Fragment>
            ) : (
              ""
            )}
          </li>
          <li className={classes.navbar__div__link}>
            <Link to="#" onClick={hamburgerClickHandler}>Contact</Link>
          </li>
          <div className="ms-5" style={{padding: "30px 25px"}}>
          <FaTwitter className="me-3"/> <FaFacebook className="me-3"/> <FaInstagram className="me-3"/> <FaYoutube className="me-3"/> 
          </div>
        </ul>
        <div className={classes.navbar__div__links_buttons}>

        </div>
      </div>
    </div>
   
  );
};

export default Navbar;

import React from "react";
import { Fragment, useState, useEffect, useRef } from "react";
import "swiper/css";
import Helmet from "react-helmet";
import Testimonials from "../assets/title-separator-2.png";
import { FaArrowAltCircleRight, FaCalendar, FaFile, FaFolder, FaHeart, FaMailBulk, FaMapMarker, FaPlay, FaQuinscape, FaShare, FaShip, FaUser, FaWallet } from "react-icons/fa";
import Map from "./Map"
import Freedom from "./Freedom";
import Obsesion from "../assets/featured-image-7.jpg";
import West from "../assets/featured-image-8.jpg"
import Sotavento from "../assets/featured-image-9.jpg"
import Obsesion1 from "../assets/featured-image-10.jpg"
import West1 from "../assets/featured-image-11.jpg"
import Sotavento1 from "../assets/featured-image-12.jpg"
import Sardinia from "../assets/featured-image-86.jpg"
import Vermenton from "../assets/featured-image-87.jpg"
import Lefkada from "../assets/featured-image-88.jpg"
import Marmaris from "../assets/featured-image-89.jpg"
import BOOKING from "../assets/pattern-image-2.png"
import about from "../assets/about-image-2.jpg";
import about2 from "../assets/video-thumb-2.jpg";
import about3 from "../assets/image-5.jpg";
import DIFFERENT from "../assets/image-6.jpg";
import RECEIVE from "../assets/image-7.jpg";
import NEWS1 from "../assets/news-image-3.jpg"
import NEWS2 from "../assets/news-image-4.jpg"
import NEWS3 from "../assets/news-image-5.jpg"
import newAuthor from "../assets/author-image-4.jpg";
import newAuthor2 from "../assets/author-image-5.jpg";
import PriceRangePicker from './PriceRangePicker';
import preLoder from "../assets/preloader.svg";
const Index2 = () => {
    const [loading, setLoading] = useState(true);

    const [count, setCount] = useState(1);
    const [formData, setFormData] = useState({
        email: '',
        designation: '',
        number: '',
        company_name: '',
        fieldname: "",
        fieldNumber: "",
        fieldDate: "",
        fieldActivity: ""
    });
    const [selectedRange, setSelectedRange] = useState({ min: 0, max: 1000 });

    const handleRangeChange = (range) => {
        setSelectedRange(range);
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const subBtn = () => {
        if (count <= 1) {
            setCount(count);
        } else {
            let sub = count - 1;
            setCount(sub)
        }

    }
    const addBtn = () => {
        let sum = count + 1;
        setCount(sum)
    }

    const elementsRef = useRef([]);

    // useEffect(() => {
    //     const handleScroll = () => {
    //       elementsRef.current.forEach((element) => {
    //         const elementPosition = element.getBoundingClientRect().top;
    //         const windowHeight = window.innerHeight;

    //         if (elementPosition < windowHeight) {
    //           element.classList.add('fadeInLeft');
    //         } else {
    //           element.classList.remove('fadeInLeft');
    //         }
    //       });
    //     };

    //     window.addEventListener('scroll', handleScroll);
    //     handleScroll(); // Trigger animation check initially
    //     return () => {
    //       window.removeEventListener('scroll', handleScroll);
    //     };
    //   }, []);
    useEffect(() => {
        const handleScroll = () => {
            elementsRef.current.forEach((element) => {
                const elementPosition = element.getBoundingClientRect().top;
                const windowHeight = window.innerHeight;

                // Check if the element is within the viewport (up or down scroll)
                const isVisible = elementPosition < windowHeight && elementPosition > -element.clientHeight;

                if (isVisible) {
                    element.classList.add('fadeInLeft');
                } else {
                    element.classList.remove('fadeInLeft');
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Trigger animation check initially
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        // Simulating page loading delay for demonstration
        const timeout = setTimeout(() => {
          setLoading(false); // Set loading to false after some time (simulating page load completion)
        }, 3000); // Change 3000 to the desired milliseconds for your loading time
    
        // Clearing timeout on component unmount to avoid memory leaks
        return () => clearTimeout(timeout);
      }, []);

    return (
        <Fragment>
            <Helmet>
                <title>Sailing - Water Sports</title>
                <meta
                    name="description"
                    content="Sailing - Water Sports"
                />

            </Helmet>

            <Freedom />
            {/* <section>
            <div class="preloader" style={{display:"none"}}><div class="icon" style={{ backgroundImage: `url(${preLoder})` }}></div></div>
            </section> */}

            <div>
      {loading ? (
        <div className="preloader">
          <div className="icon" style={{ backgroundImage: `url(${preLoder})` }}></div>
        </div>
      ) : (
        <div>
             <section ref={(el) => elementsRef.current.push(el)}>
                <div className="containerFluid fadeInLeft bookingSection">
                    <div className="imageLayer" style={{ backgroundImage: `url(${BOOKING})` }}></div>
                    <div className="container" style={{ maxWidth: "1200px" }}>
                        <div className="row">
                            <div className="titleCol col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                <div className="innerDIV">
                                    <div className="titlBox">
                                        <h2><span>Start</span> <br />booking.</h2>
                                        <div className="suBTitle">Largest Yacht selection for booking</div>
                                    </div>
                                </div>
                            </div>
                            <div className="forMCol col-xl-8 col-lg-12 col-md-12 col-sm-12">
                                <div className="inner">
                                    <div className="default-form booking-form">
                                        <form>
                                            <div className="row clearfix">

                                                <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                                    <div className="field-inner">
                                                        <div className="field-icon"><FaMapMarker className="STARTFaMapMarker" /></div>
                                                        <select className="custom-select-box" name="field-name" id="ui-id-1" >
                                                            <option>Depature Location</option>
                                                            <option>Dubai</option>
                                                            <option>California</option>
                                                            <option>Dhaka</option>
                                                            <option>Mumbai</option>
                                                        </select>
                                                        {/* <span tabindex="0" id="ui-id-1-button" role="combobox" aria-expanded="false" aria-autocomplete="list" aria-owns="ui-id-1-menu" aria-haspopup="true" className="ui-selectmenu-button ui-button ui-widget ui-selectmenu-button-closed ui-corner-all" aria-activedescendant="ui-id-4" aria-labelledby="ui-id-4" aria-disabled="false"><span className="ui-selectmenu-icon ui-icon ui-icon-triangle-1-s"></span><span className="ui-selectmenu-text">Depature Location</span></span> */}
                                                    </div>
                                                </div>

                                                <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                                    <div className="field-inner">
                                                        <div className="field-icon">
                                                            {/* <FaClock /> */}
                                                        </div>
                                                        <input type="date" className="datetime-picker" name="field-name" placeholder="Date &amp; Time" required="" />
                                                    </div>
                                                </div>

                                                <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                                    <div className="field-inner">
                                                        <div className="field-icon"><span className="flaticon-stopwatch"></span></div>
                                                        <select className="custom-select-box" name="field-name" id="ui-id-2" >
                                                            <option >Duration</option>
                                                            <option >03 Days</option>
                                                            <option >05 Days</option>
                                                            <option >07 Days</option>
                                                            <option >10 Days</option>
                                                            <option >15 Days</option>
                                                        </select>
                                                        {/* <span tabindex="0" id="ui-id-2-button" role="combobox" aria-expanded="false" aria-autocomplete="list" aria-owns="ui-id-2-menu" aria-haspopup="true" className="ui-selectmenu-button ui-selectmenu-button-closed ui-corner-all ui-button ui-widget"><span className="ui-selectmenu-icon ui-icon ui-icon-triangle-1-s"></span><span className="ui-selectmenu-text">Duration</span></span> */}
                                                    </div>
                                                </div>

                                                <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                                    <div className="field-inner">
                                                        <div className="field-icon"><span className="flaticon-user-3"></span></div>
                                                        <select className="custom-select-box" name="field-name" id="ui-id-3" >
                                                            <option>No. of guests</option>
                                                            <option>01 People</option>
                                                            <option>02 People</option>
                                                            <option>03 People</option>
                                                            <option>04 People</option>
                                                            <option>05 People</option>
                                                        </select>
                                                        {/* <span tabindex="0" id="ui-id-3-button" role="combobox" aria-expanded="false" aria-autocomplete="list" aria-owns="ui-id-3-menu" aria-haspopup="true" className="ui-selectmenu-button ui-selectmenu-button-closed ui-corner-all ui-button ui-widget"><span className="ui-selectmenu-icon ui-icon ui-icon-triangle-1-s"></span><span className="ui-selectmenu-text">No. of guests</span></span> */}
                                                    </div>
                                                </div>

                                                <div className="form-group col-lg-8 col-md-6 col-sm-12">
                                                    <div className="field-inner">
                                                        <div className="range-box">
                                                            <div className="range-info clearfix">
                                                                <label for="amount-1">Price Range</label>
                                                                <div className="priceRAngaeBook">
                                                                    ${selectedRange.min} - ${selectedRange.max}
                                                                </div>
                                                                <div style={{ margin: '20px' }}>
                                                                    <PriceRangePicker onChange={handleRangeChange} />

                                                                </div>
                                                            </div>
                                                            <div className="range-slider-1 ui-slider ui-corner-all ui-slider-horizontal ui-widget ui-widget-content"><div className="ui-slider-range ui-corner-all ui-widget-header" ></div><span tabindex="0" className="ui-slider-handle ui-corner-all ui-state-default"></span><span tabindex="0" className="ui-slider-handle ui-corner-all ui-state-default" ></span></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                                    <button type="submit" className="theme-btn btn-style-three"><span className="btn-title">Book Your Yacht</span></button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="fadeInLeft" style={{ padding: "0px 0px 70px" }} ref={(el) => elementsRef.current.push(el)}>
                <div className="containerFluid fadeInLeft activitiesSection">
                    <div className="container" style={{ paddingTop: "30px" }}>
                        <div className="text-center" style={{ marginBottom: "50px" }}>
                            <p className="newsPara">WHERE DO YOU WANT TO GO?</p>
                            <h2 className="newHeder">REASON FOR <strong>CHOOSE US</strong></h2>
                            <div style={{ position: "relative", paddingBottom: "28px" }}>
                                <div className="separator" style={{ backgroundImage: `url(${Testimonials})`, left: "47%", height: "30px" }}></div>
                            </div>
                            <div className="row" style={{ marginTop: "50px" }}>
                                <div className="featuredService col-xl-4 col-lg-4 col-md-6 col-sm-12" ref={(el) => elementsRef.current.push(el)}>
                                    <div className="innerBox wow fadeInLeft animated">
                                        <div className="iconBoxss">
                                            <p><FaShip /> </p>
                                        </div>
                                        <div className="contentboX">
                                            <div className="titleboX">
                                                <h4>Yacht Tour</h4>
                                                <div className="subtitle">top landmarks &amp; amazing</div>
                                            </div>
                                            <div className="text">Their duty through weakness it will which is same as saying through shrinking from toil.</div>
                                        </div>

                                        <div className='seeAll '>

                                            <a href="#" className="theme-btn btnStyleFive"><div className="btntitleALL">Tour Booking</div></a>

                                        </div>
                                    </div>
                                </div>
                                <div className="featuredService col-xl-4 col-lg-4 col-md-6 col-sm-12 phonefeaturedService" ref={(el) => elementsRef.current.push(el)}>
                                    <div className="innerBox wow fadeInLeft animated">
                                        <div className="iconBoxss">
                                            <p><FaShip /> </p>
                                        </div>
                                        <div className="contentboX">
                                            <div className="titleboX">
                                                <h4>Yacht Rental</h4>
                                                <div className="subtitle">World’s Most Exclusive</div>
                                            </div>
                                            <div className="text">Their duty through weakness it will which is same as saying through shrinking from toil.</div>
                                        </div>

                                        <div className='seeAll '>

                                            <a href="#" className="theme-btn btnStyleFive"><div className="btntitleALL">Tour Booking</div></a>

                                        </div>
                                    </div>
                                </div>
                                <div className="featuredService col-xl-4 col-lg-4 col-md-6 col-sm-12" ref={(el) => elementsRef.current.push(el)}>
                                    <div className="innerBox wow fadeInLeft animated">
                                        <div className="iconBoxss">
                                            <p><FaShip /> </p>
                                        </div>
                                        <div className="contentboX">
                                            <div className="titleboX">
                                                <h4>Water Sports</h4>
                                                <div className="subtitle">Fun & Thrilling Adventure</div>
                                            </div>
                                            <div className="text">Their duty through weakness it will which is same as saying through shrinking from toil.</div>
                                        </div>

                                        <div className='seeAll '>

                                            <a href="#" className="theme-btn btnStyleFive"><div className="btntitleALL">Tour Booking</div></a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container-fluid fadeInLeft" style={{ position: "relative", background: "#061138", padding: "90px 0px" }}>
                <div className="container">
                    <div className="row" style={{ paddingBottom: "20px" }}>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div>
                                <p className="newsPara">TRY THE ULTIMATE EXPERIENCE</p>
                                <h2 className="newHeder" style={{ color: "#ffffff" }}>LUXURY YACHT <strong>RENTAL</strong></h2>
                            </div>
                            <div style={{ position: "relative", paddingBottom: "28px" }}>
                                <div className="separator" style={{ backgroundImage: `url(${Testimonials})`, height: "30px" }}></div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="btnDiv btnFloat">
                                <div className="btnNew" style={{ color: "#fff", borderColor: "#fff" }}> View All Locations

                                </div>
                            </div>
                        </div>


                    </div>

                    <div className="row">
                        <div className="rentalBlock mix all india col-lg-4 col-md-6 col-sm-12">
                            <div className="inneRBox">
                                <div className="imagEBbox">
                                    <figure className="image">
                                        <a href="yacht-rent-buy-single.html"><img src={Obsesion} alt="" title="" /></a>
                                    </figure>
                                    <div className="pricENBox">
                                        <div className="price">$7,400</div>
                                        <div className="cycle">Per Day</div>
                                    </div>
                                </div>
                                <div className="titlebOx">
                                    <div className="more-link-box">
                                        <a href="yacht-rent-buy-single.html" className="theme-btn">View Details</a>
                                    </div>
                                    <h4><a href="yacht-rent-buy-single.html">Obsesion</a></h4>
                                    <div className="info1">177’ / 53.65 m / Sensation Yacht</div>
                                </div>
                                <div className="meta-info">
                                    <ul className="clearfix">
                                        <li><a href="#"><FaCalendar /> Build Year:  2013</a></li>
                                        <li><a href="#"><FaUser /> Guest:  Max 12</a></li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div className="rentalBlock mix all india col-lg-4 col-md-6 col-sm-12 phonerentalBlock">
                            <div className="inneRBox">
                                <div className="imagEBbox">
                                    <figure className="image">
                                        <a href="yacht-rent-buy-single.html"><img src={West} alt="" title="" /></a>
                                    </figure>
                                    <div className="pricENBox">
                                        <div className="price">$6,550</div>
                                        <div className="cycle">Per Day</div>
                                    </div>
                                </div>
                                <div className="titlebOx">
                                    <div className="more-link-box">
                                        <a href="yacht-rent-buy-single.html" className="theme-btn">View Details</a>
                                    </div>
                                    <h4><a href="yacht-rent-buy-single.html">West Port Z12</a></h4>
                                    <div className="info1">150’ / 50 M / COSMO EXPLORER</div>
                                </div>
                                <div className="meta-info">
                                    <ul className="clearfix">
                                        <li><a href="#"><FaCalendar /> Build Year:  2013</a></li>
                                        <li><a href="#"><FaUser /> GUEST: MAX 10</a></li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div className="rentalBlock mix all india col-lg-4 col-md-6 col-sm-12">
                            <div className="inneRBox">
                                <div className="imagEBbox">
                                    <figure className="image">
                                        <a href="yacht-rent-buy-single.html"><img src={Sotavento} alt="" title="" /></a>
                                    </figure>
                                    <div className="pricENBox">
                                        <div className="price">$8,450</div>
                                        <div className="cycle">Per Day</div>
                                    </div>
                                </div>
                                <div className="titlebOx">
                                    <div className="more-link-box">
                                        <a href="yacht-rent-buy-single.html" className="theme-btn">View Details</a>
                                    </div>
                                    <h4><a href="yacht-rent-buy-single.html">Sotavento</a></h4>
                                    <div className="info1">144’15” / 48.26 M / CHRISTENSEN</div>
                                </div>
                                <div className="meta-info">
                                    <ul className="clearfix">
                                        <li><a href="#"><FaCalendar /> Build Year:  2013</a></li>
                                        <li><a href="#"><FaUser /> Guest:  Max 08</a></li>
                                    </ul>
                                </div>
                            </div>

                        </div>




                        <div className="rentalBlock mix all india col-lg-4 col-md-6 col-sm-12" style={{ marginTop: "50px" }}>
                            <div className="inneRBox">
                                <div className="imagEBbox">
                                    <figure className="image">
                                        <a href="yacht-rent-buy-single.html"><img src={Obsesion1} alt="" title="" /></a>
                                    </figure>
                                    <div className="pricENBox">
                                        <div className="price">$7,400</div>
                                        <div className="cycle">Per Day</div>
                                    </div>
                                </div>
                                <div className="titlebOx">
                                    <div className="more-link-box">
                                        <a href="yacht-rent-buy-single.html" className="theme-btn">View Details</a>
                                    </div>
                                    <h4><a href="yacht-rent-buy-single.html">Obsesion</a></h4>
                                    <div className="info1">177’ / 53.65 m / Sensation Yacht</div>
                                </div>
                                <div className="meta-info">
                                    <ul className="clearfix">
                                        <li><a href="#"><FaCalendar /> Build Year:  2013</a></li>
                                        <li><a href="#"><FaUser /> Guest:  Max 12</a></li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div className="rentalBlock mix all india col-lg-4 col-md-6 col-sm-12 phonerentalBlock" style={{ marginTop: "50px" }}>
                            <div className="inneRBox">
                                <div className="imagEBbox">
                                    <figure className="image">
                                        <a href="yacht-rent-buy-single.html"><img src={West1} alt="" title="" /></a>
                                    </figure>
                                    <div className="pricENBox">
                                        <div className="price">$6,550</div>
                                        <div className="cycle">Per Day</div>
                                    </div>
                                </div>
                                <div className="titlebOx">
                                    <div className="more-link-box">
                                        <a href="yacht-rent-buy-single.html" className="theme-btn">View Details</a>
                                    </div>
                                    <h4><a href="yacht-rent-buy-single.html">West Port Z12</a></h4>
                                    <div className="info1">150’ / 50 M / COSMO EXPLORER</div>
                                </div>
                                <div className="meta-info">
                                    <ul className="clearfix">
                                        <li><a href="#"><FaCalendar /> Build Year:  2013</a></li>
                                        <li><a href="#"><FaUser /> GUEST: MAX 10</a></li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div className="rentalBlock mix all india col-lg-4 col-md-6 col-sm-12" style={{ marginTop: "50px" }}>
                            <div className="inneRBox">
                                <div className="imagEBbox">
                                    <figure className="image">
                                        <a href="yacht-rent-buy-single.html"><img src={Sotavento1} alt="" title="" /></a>
                                    </figure>
                                    <div className="pricENBox">
                                        <div className="price">$8,450</div>
                                        <div className="cycle">Per Day</div>
                                    </div>
                                </div>
                                <div className="titlebOx">
                                    <div className="more-link-box">
                                        <a href="yacht-rent-buy-single.html" className="theme-btn">View Details</a>
                                    </div>
                                    <h4><a href="yacht-rent-buy-single.html">Sotavento</a></h4>
                                    <div className="info1">144’15” / 48.26 M / CHRISTENSEN</div>
                                </div>
                                <div className="meta-info">
                                    <ul className="clearfix">
                                        <li><a href="#"><FaCalendar /> Build Year:  2013</a></li>
                                        <li><a href="#"><FaUser /> Guest:  Max 08</a></li>
                                    </ul>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="row" style={{ marginTop: "50px" }}>

                    </div>

                    <div className="bottom-text">Sailing Offers Over 246 Luxury Yacht from Different Destinations. <a href="yacht-rent-buy.html">View All Our Yacht.</a></div>
                </div>
            </section>
            <section className="fadeInLeft" style={{ padding: "0px 0px 70px" }} ref={(el) => elementsRef.current.push(el)}>
                <div className="containerFluid fadeInLeft activitiesSection">
                    <div className="container" style={{ paddingTop: "30px" }}>
                        <div className="text-center" style={{ marginBottom: "50px" }}>
                            <p className="newsPara">WHERE DO YOU WANT TO GO?</p>
                            <h2 className="newHeder">POPULAR <strong>DESTINATIONS</strong></h2>
                            <div style={{ position: "relative", paddingBottom: "28px" }}>
                                <div className="separator" style={{ backgroundImage: `url(${Testimonials})`, left: "47%", height: "30px" }}></div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="destinationBlock col-lg-3 col-md-6 col-sm-12  fadeInLeft animated">
                                <div className="inneRBox">
                                    <div className="imaGEBox">
                                        <figure className="image">
                                            <a href="yacht-rent-buy.html"><img src={Sardinia} alt="" title="" /></a>
                                        </figure>
                                        <div className="caption-box">
                                            <div className="title">Yachts for Rental</div>
                                            <div className="count">24</div>
                                        </div>
                                        <div className="hover-box">
                                            <div className="hover-inner">
                                                <div className="hover-content">
                                                    <div className="icon-box">
                                                        <FaShip />
                                                    </div>
                                                    <h5 className="title">24 Yachts</h5>
                                                    <div className="link-box">
                                                        <a href="yacht-rent-buy.html" className="theme-btn btn-style-two"><div className="btn-title">View All Yachts</div></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="title-box">
                                        <h4><a href="yacht-rent-buy.html">Sardinia</a></h4>
                                    </div>
                                    <div className="meta-info">
                                        <ul className="clearfix">
                                            <li><a href="#"><FaShip className="destinationIcon" /> 05 Sailing</a></li>
                                            <li><a href="#"><FaShip className="destinationIcon" />  03 Motor</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="destinationBlock col-lg-3 col-md-6 col-sm-12  fadeInLeft animated">
                                <div className="inneRBox">
                                    <div className="imaGEBox">
                                        <figure className="image">
                                            <a href="yacht-rent-buy.html"><img src={Vermenton} alt="" title="" /></a>
                                        </figure>
                                        <div className="caption-box">
                                            <div className="title">Yachts for Rental</div>
                                            <div className="count">24</div>
                                        </div>
                                        <div className="hover-box">
                                            <div className="hover-inner">
                                                <div className="hover-content">
                                                    <div className="icon-box">
                                                        <FaShip />
                                                    </div>
                                                    <h5 className="title">08 Yachts</h5>
                                                    <div className="link-box">
                                                        <a href="yacht-rent-buy.html" className="theme-btn btn-style-two"><div className="btn-title">View All Yachts</div></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="title-box">
                                        <h4><a href="yacht-rent-buy.html">Vermenton</a></h4>
                                    </div>
                                    <div className="meta-info">
                                        <ul className="clearfix">
                                            <li><a href="#"><FaShip className="destinationIcon" /> 03 Sailing</a></li>
                                            <li><a href="#"><FaShip className="destinationIcon" />  05 Motor</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="destinationBlock col-lg-3 col-md-6 col-sm-12  fadeInLeft animated">
                                <div className="inneRBox">
                                    <div className="imaGEBox">
                                        <figure className="image">
                                            <a href="yacht-rent-buy.html"><img src={Lefkada} alt="" title="" /></a>
                                        </figure>
                                        <div className="caption-box">
                                            <div className="title">Yachts for Rental</div>
                                            <div className="count">30</div>
                                        </div>
                                        <div className="hover-box">
                                            <div className="hover-inner">
                                                <div className="hover-content">
                                                    <div className="icon-box">
                                                        <FaShip />
                                                    </div>
                                                    <h5 className="title">30 Yachts</h5>
                                                    <div className="link-box">
                                                        <a href="yacht-rent-buy.html" className="theme-btn btn-style-two"><div className="btn-title">View All Yachts</div></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="title-box">
                                        <h4><a href="yacht-rent-buy.html">Lefkada</a></h4>
                                    </div>
                                    <div className="meta-info">
                                        <ul className="clearfix">
                                            <li><a href="#"><FaShip className="destinationIcon" /> 16 Sailing</a></li>
                                            <li><a href="#"><FaShip className="destinationIcon" />  14 Motor</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="destinationBlock col-lg-3 col-md-6 col-sm-12  fadeInLeft animated">
                                <div className="inneRBox">
                                    <div className="imaGEBox">
                                        <figure className="image">
                                            <a href="yacht-rent-buy.html"><img src={Marmaris} alt="" title="" /></a>
                                        </figure>
                                        <div className="caption-box">
                                            <div className="title">Yachts for Rental</div>
                                            <div className="count">15</div>
                                        </div>
                                        <div className="hover-box">
                                            <div className="hover-inner">
                                                <div className="hover-content">
                                                    <div className="icon-box">
                                                        <FaShip />
                                                    </div>
                                                    <h5 className="title">24 Yachts</h5>
                                                    <div className="link-box">
                                                        <a href="yacht-rent-buy.html" className="theme-btn btn-style-two"><div className="btn-title">View All Yachts</div></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="title-box">
                                        <h4><a href="yacht-rent-buy.html">Sardinia</a></h4>
                                    </div>
                                    <div className="meta-info">
                                        <ul className="clearfix">
                                            <li><a href="#"><FaShip className="destinationIcon" /> 07 Sailing</a></li>
                                            <li><a href="#"><FaShip className="destinationIcon" />  08 Motor</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <p className="continueHover"><FaArrowAltCircleRight style={{ marginRight: "10px" }} /><span className="continueSapn">VIEW ALL DESTINATIONS</span></p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="aboutSectionTwo fadeInLeft" ref={(el) => elementsRef.current.push(el)}>
                <div className=" fadeInLeft">
                    <div className="imageBoxDiv">

                        <div className="imageLayerabout" style={{ backgroundImage: `url(${about})` }} ref={(el) => elementsRef.current.push(el)}></div>
                    </div>
                    <div className="image-box-right ">

                        <div className="image-layer" style={{ backgroundImage: `url(${about3})` }} ref={(el) => elementsRef.current.push(el)}></div>
                    </div>
                    <div className="containerFluid">

                        <div className="container">
                            <div className="contenOuter">

                                <div className="content-inner">
                                    <div className="content-box wow fadeInUp animated" data-wow-delay="0ms" data-wow-duration="1500ms"
                                    //  style="visibility: visible; animation-duration: 1500ms; animation-delay: 0ms; animation-name: fadeInUp;"
                                    >
                                        <div className="sec-title-two with-border">
                                            <div className="upper-text">Our Experience</div>
                                            <h2>Story <strong>About us</strong></h2>
                                            <div className="separator" style={{ backgroundImage: `url(${Testimonials})`, height: "30px" }}></div>

                                        </div>
                                        <h4>World’s Largest Yachting Company Offers Yachts for Low Rental.</h4>
                                        <div className="text1">Sailing Yachts was born in Thailand – near Venice in 1995 with  one mission: to “design and build high quality boats for sailors seeking performance and exclusivity”</div>
                                        <div className="lower-row clearfix">
                                            <div className="info">
                                                <div className="name">Justin Homerey</div>
                                                <div className="designation">CEO &amp; Founder</div>
                                            </div>
                                            <div className="link-box">
                                                <a href="about.html" className="theme-btn btn-style-three"><span className="btn-title">More About Us</span></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="video-link wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms"
                                    // style="visibility: visible; animation-duration: 1500ms; animation-delay: 0ms; animation-name: fadeInLeft;"
                                    >
                                        <div className="video-thumb">
                                            <figure className="image"><img src={about2} alt="" title="" /></figure>
                                            <a href="https://www.youtube.com/watch?v=UHWwQtNqtmk" className="overlink lightbox-image">
                                                {/* <span className="icon flaticon-media-play-symbol"></span> */}
                                                <span className="playBtnCircle"><FaPlay /></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container-fluid qualitySection fadeInLeft">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12 qualityDiv">
                                <div className="innerBoxBest">
                                    <div className="iconBox">
                                        <div className="octagonWrap">
                                            <div className="octagon"></div>
                                        </div>
                                        <span className="qtyLog">
                                            <FaQuinscape className="logoQty" />

                                        </span>
                                    </div>
                                    <div className="content">
                                        <h4 className="QualityHeder">Quality &amp;<br></br>Best Equipments</h4>
                                        <div className="Qualitytext">Perfectly simple &amp; easy to in our hour, when our power.</div>
                                        <div className="link-box">
                                            <a href="#" className="default-link">
                                                <span className="icon flaticon-logout"></span>

                                                <p className="continueHover" style={{ color: "#FFF" }}><FaArrowAltCircleRight style={{ marginRight: "10px" }} /><span className="continueSapna">More About Surfing</span></p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 qualityDiv">
                                <div className="innerBoxBest">
                                    <div className="iconBox">
                                        <div className="octagonWrap">
                                            <div className="octagon"></div>
                                        </div>
                                        <span className="qtyLog">
                                            <FaUser className="logoQty" />

                                        </span>
                                    </div>
                                    <div className="content">
                                        <h4 className="QualityHeder">Professional  &amp;<br></br>Expert Instroctors</h4>
                                        <div className="Qualitytext">The wise man therefore always holds in these matters.
                                        </div>
                                        <div className="link-box">
                                            <a href="#" className="default-link">
                                                <span className="icon flaticon-logout"></span>

                                                <p className="continueHover" style={{ color: "#FFF" }}><FaArrowAltCircleRight style={{ marginRight: "10px" }} /><span className="continueSapna">More About Surfing</span></p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 qualityDiv">
                                <div className="innerBoxBest">
                                    <div className="iconBox">
                                        <div className="octagonWrap">
                                            <div className="octagon"></div>
                                        </div>
                                        <span className="qtyLog">
                                            <FaWallet className="logoQty" />

                                        </span>
                                    </div>
                                    <div className="content">
                                        <h4 className="QualityHeder">Value for<br></br> Spending Money</h4>
                                        <div className="Qualitytext">Pleasures to secure other greater he endures worse pains.</div>
                                        <div className="link-box">
                                            <a href="#" className="default-link">
                                                <span className="icon flaticon-logout"></span>

                                                <p className="continueHover" style={{ color: "#FFF" }}><FaArrowAltCircleRight style={{ marginRight: "10px" }} /><span className="continueSapna">More About Surfing</span></p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="testimonalDiv fadeInLeft" >
                <div className="container-fluid" >
                    <div className="imgTestimonal"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="text-center">
                                    <p className="newsPara" >TESTIMONIALS</p>
                                    <h2 className="newHeder">TRAVELLERS <strong>EXPERIENCES</strong></h2>
                                    <div style={{ position: "relative", paddingBottom: "28px" }}>
                                        <div className="separator" style={{ backgroundImage: `url(${Testimonials})`, left: "47%", height: "30px" }}></div>
                                    </div>

                                </div>
                                <Map />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="fadeInLeft">
                <div className=" bckIMg">
                    <div className="image-layer left-layer" style={{ backgroundImage: `url(${DIFFERENT})` }}></div>
                    <div className="image-layer right-layer" style={{ backgroundImage: `url(${RECEIVE})` }}></div>
                    <div className="shapes">
                        <div className="shape one"></div>
                        <div className="shape two"></div>
                    </div>
                    <div className="">
                        <div className="column left-column clearfix">
                            <div className="image-layer left-layer" style={{ backgroundImage: `url(${DIFFERENT})` }}></div>
                            <div className="inner">
                                <div className="content">
                                    <div className="subtitle">there anyone who loves or pursues</div>
                                    <h2>Different Movements <br />Everyday</h2>
                                    <div className="link-box">
                                        <a href="yacht-rent-buy.html" className="theme-btn btn-style-two"><div className="btn-title">Yacht Booking</div></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="column right-column clearfix">
                            <div className="image-layer left-layer" style={{ backgroundImage: `url(${RECEIVE})` }}></div>
                            <div className="inner">
                                <div className="content">
                                    <div className="subtitle">Newsletter Subscription</div>
                                    <h2>Receive Latest Updates <br />to Your Inbox</h2>
                                    <div className="link-box">
                                        <a href="contact.html" className="theme-btn btn-style-two"><div className="btn-title">Subscribe Us</div></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="fadeInLeft" style={{ padding: "0px 0px 70px" }} ref={(el) => elementsRef.current.push(el)}>
                <div className="containerFluid fadeInLeft activitiesSection">
                    <div className="container" style={{ paddingTop: "30px" }}>
                        <div className="text-center" style={{ marginBottom: "50px" }}>
                            <p className="newsPara">NEWS & UPDATES</p>
                            <h2 className="newHeder">LATEST FROM <strong>BLOG</strong></h2>
                            <div style={{ position: "relative", paddingBottom: "28px" }}>
                                <div className="separator" style={{ backgroundImage: `url(${Testimonials})`, left: "47%", height: "30px" }}></div>
                            </div>
                            <div className="row" style={{ marginTop: "50px" }}>
                                <div className="news-block-two col-lg-4 col-md-6 col-sm-12  fadeInLeft animated" ref={(el) => elementsRef.current.push(el)}>
                                    <div className="inner-box">
                                        <div className="image-box">
                                            <figure className="image">
                                                <a href="blog-single.html"><img src={NEWS1} alt="" title="" /></a>
                                            </figure>
                                            <a href="blog-single.html" className="link-layer"><span className="newsIcons"><FaFile /></span></a>
                                            <div className="post-date"><span className="day">10</span><span className="month">Nov</span></div>
                                        </div>
                                        <div className="lower-content">

                                            <div className="content">
                                                <div className="meta-info1">
                                                    <ul className="clearfix">
                                                        <li>
                                                            <FaFolder className="newSFolder" />
                                                            <a href="#">Sailing Tips</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <h5><a href="blog-single.html">How to Plan a Yacht Trip With Your Friends?</a></h5>
                                                <div className="author">
                                                    <img src={newAuthor} alt="" title="" />
                                                    <span className="name">By Williamson</span>
                                                </div>
                                            </div>
                                            <div className="other-links">
                                                <div className="add-to-fav">
                                                    <a href="#"><FaHeart /></a>
                                                </div>
                                                <div className="add-comment">
                                                    <a href="#"><FaMailBulk /></a>
                                                </div>
                                                <div className="share-it">
                                                    <a className="share-btn" href="#"><FaShare /></a>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="news-block-two col-lg-4 col-md-6 col-sm-12  fadeInLeft animated" ref={(el) => elementsRef.current.push(el)}>
                                    <div className="inner-box">
                                        <div className="image-box">
                                            <figure className="image">
                                                <a href="blog-single.html"><img src={NEWS2} alt="" title="" /></a>
                                            </figure>
                                            <a href="blog-single.html" className="link-layer"><span className="newsIcons"><FaFile /></span></a>
                                            <div className="post-date"><span className="day">05</span><span className="month">Nov</span></div>
                                        </div>
                                        <div className="lower-content">

                                            <div className="content">
                                                <div className="meta-info1">
                                                    <ul className="clearfix">
                                                        <li>
                                                            <FaFolder className="newSFolder" />
                                                            <a href="#">SAILING STORIES</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <h5><a href="blog-single.html">What awaits La Palma Sailing Enthusiasts?</a></h5>
                                                <div className="author">
                                                    <img src={newAuthor2} alt="" title="" />
                                                    <span className="name">By Mark Leonard</span>
                                                </div>
                                            </div>
                                            <div className="other-links">
                                                <div className="add-to-fav">
                                                    <a href="#"><FaHeart /></a>
                                                </div>
                                                <div className="add-comment">
                                                    <a href="#"><FaMailBulk /></a>
                                                </div>
                                                <div className="share-it">
                                                    <a className="share-btn" href="#"><FaShare /></a>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="news-block-two col-lg-4 col-md-6 col-sm-12  fadeInLeft animated" ref={(el) => elementsRef.current.push(el)}>
                                    <div className="inner-box">
                                        <div className="image-box">
                                            <figure className="image">
                                                <a href="blog-single.html"><img src={NEWS3} alt="" title="" /></a>
                                            </figure>
                                            <a href="blog-single.html" className="link-layer"><span className="newsIcons"><FaFile /></span></a>
                                            <div className="post-date"><span className="day">24</span><span className="month">Oct</span></div>
                                        </div>
                                        <div className="lower-content">

                                            <div className="content">
                                                <div className="meta-info1">
                                                    <ul className="clearfix">
                                                        <li>
                                                            <FaFolder className="newSFolder" />
                                                            <a href="#">Sailing Tips</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <h5><a href="blog-single.html">How to Plan a Yacht Trip With Your Friends?</a></h5>
                                                <div className="author">
                                                    <img src={newAuthor} alt="" title="" />
                                                    <span className="name">By Williamson</span>
                                                </div>
                                            </div>
                                            <div className="other-links">
                                                <div className="add-to-fav">
                                                    <a href="#"><FaHeart /></a>
                                                </div>
                                                <div className="add-comment">
                                                    <a href="#"><FaMailBulk /></a>
                                                </div>
                                                <div className="share-it">
                                                    <a className="share-btn" href="#"><FaShare /></a>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
      )}
    </div>


           

        </Fragment>
    );
};

export default Index2;

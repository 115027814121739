import 'jquery'; // Import jQuery
import React, { useEffect,useRef } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'owl.carousel';
import test1 from "../assets/5.jpg";
import test2 from "../assets/4.jpg";
import test3 from "../assets/6.jpg";
// import { FaArrowAltCircleRight, FaFly } from "react-icons/fa";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
const Freedom = () => {
    const owlCarouselRef = useRef(null);
    useEffect(() => {
        // Ensure $ is available when the component mounts
        const $ = window.$;

        // Initialize Owl Carousel
        owlCarouselRef.current = $('#carousel3').owlCarousel({
            loop: true,
            margin: 10,
            nav: true,
            touchDrag: true,
                autoplay: true, // Enable autoplay
            autoplayTimeout: 2000, // Set autoplay timeout in milliseconds (e.g., 3000 for 3 seconds)
      
            navText: [
              '<FaStar />', // Left arrow icon, you can replace this with your own HTML or icon class
              '<i className="bi bi-arrow-right"></i>' // Right arrow icon, you can replace this with your own HTML or icon class
            ],
            responsive: {
                0: {
                    items: 1
                },
                600: {
                    items: 1
                },
                1000: {
                    items: 1,
                }
            }
            
        });
    }, []); // Empty dependency array ensures the effect runs once on mount
    const nextSlide = () => {
        owlCarouselRef.current.trigger('next.owl.carousel');
      };
    
      const prevSlide = () => {
        owlCarouselRef.current.trigger('prev.owl.carousel');
      };
    return (
        <div className="fadeInLeft" id="carousel1" style={{background:"#0c1740"}}>
            <div className="">
                <div className="owl-carousel" id='carousel3'>
                    {/* Add your card components here */}
                    <div className='FreedomDiv'>
                        <div className='FreedomBox' style={{ backgroundImage: `url(${test2})` }}>

                        </div>
                        <div className="autoContainerDiv">
                            <div className="contentBoxSkiing">
                                <div className="contentSkiing">
                                    <div className="innerSkiing textFreedom">
                                        <h1>Luxury Yacht<br></br>Rental</h1>
                                        <div className="textSkiing">Foresee the pain and trouble that are bound to ensue and equal fail in their duty through weakness.</div>
                                        <div className="linksboxSkiing">
                                            <a href="#" className="themeBtnSkiing btn-style-two"><div className="btnTitleExplore">Explore More</div></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='FreedomDiv'>
                        <div className='FreedomBox' style={{ backgroundImage: `url(${test3})` }}>

                        </div>
                        <div className="autoContainerDiv">
                            <div className="contentBoxSkiing">
                                <div className="contentSkiing">
                                    <div className="innerSkiing textFreedom">
                                        <h1>Sailing Tour  <br></br>Newport</h1>
                                        <div className="textSkiing">Foresee the pain and trouble that are bound to ensue and equal fail in their duty through weakness. </div>
                                        <div className="linksboxSkiing">
                                            <a href="#" className="themeBtnSkiing btn-style-two"><div className="btnTitleExplore">Explore More</div></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='FreedomDiv'>
                        <div className='FreedomBox' style={{ backgroundImage: `url(${test1})` }}>

                        </div>
                        <div className="autoContainerDiv">
                            <div className="contentBoxSkiing">
                                <div className="contentSkiing">
                                    <div className="innerSkiing innerFloat textFreedom">
                                        <h1>Book Your <br></br>Fantastic Cabin</h1>
                                        <div className="textSkiing">Foresee the pain and trouble that are bound to ensue and equal fail in their duty through weakness. </div>
                                        <div className="linksboxSkiing">
                                            <a href="#" className="themeBtnSkiing btn-style-two"><div className="btnTitleExplore">Explore More</div></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                 
                </div>
                <div className="owlBtn" style={{zIndex:"2"}}>
          <button className=" leftBtn" onClick={prevSlide} style={{backgroundColor:"#fff"}}>
            <FaAngleLeft style={{color:"#acb7c6",fontSize:"20px"}} />
          </button>
          
          <button className=" rightBtn" onClick={nextSlide} style={{backgroundColor:"#fff"}}>
            <FaAngleRight style={{color:"#acb7c6",fontSize:"20px"}} />
          </button>
          
        </div>
            </div>
        </div>
    );
};

export default Freedom;

import { Fragment, useLayoutEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Footer from "./components/footer/Footer";
import OurCompany from "./pages/OurCompany";
import Page404 from "./pages/404";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "./components/navbar/Navbar";
import Index2 from "./pages/Index2";

function App() {
  const location = useLocation();
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <Fragment>
      {location.pathname === '/' ? <Navbar /> : <Navbar /> }
      <Routes>
        {/* <Route path="/" element={<OurCompany />} /> */}
        <Route path="/" element={<Index2 />} />
        <Route path="/*" element={<Page404 />} />
      </Routes>
      {location.pathname === '/' ? <Footer /> : <Footer /> }

    </Fragment>
  );
}

export default App;

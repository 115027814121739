import React, { useState } from 'react';
import { Slider, InputNumber, Row, Col } from 'antd';
import 'rc-slider/assets/index.css';

const PriceRangePicker = ({ onChange }) => {
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(1000);

  const handleMinChange = (value) => {
    setMinPrice(value);
    onChange({ min: value, max: maxPrice });
  };

  const handleMaxChange = (value) => {
    setMaxPrice(value);
    onChange({ min: minPrice, max: value });
  };

  return (
    <Row>
      <Col span={12}>
        <Slider
          range
          min={0}
          max={1000}
          value={[minPrice, maxPrice]}
          onChange={([min, max]) => {
            setMinPrice(min);
            setMaxPrice(max);
            onChange({ min, max });
          }}
        />
      </Col>
    
      
    </Row>
  );
};

export default PriceRangePicker;

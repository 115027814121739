import React, { Fragment } from "react";
import "./Footer.module.css";
import { FaPhoneAlt, FaMapMarker, FaFacebook, FaTwitter, FaYoutube, FaWifi, FaFacebookMessenger, FaMailchimp, FaMailBulk } from "react-icons/fa";
import { Link } from "react-router-dom";
import footerLogo from "../../assets/footer-logo.png"
import footerimg1 from "../../assets/footer/gallery-thumb-1.jpg";
import footerimg2 from "../../assets/footer/gallery-thumb-2.jpg";
import footerimg3 from "../../assets/footer/gallery-thumb-3.jpg";
import footerimg4 from "../../assets/footer/gallery-thumb-4.jpg";
import footerimg5 from "../../assets/footer/gallery-thumb-5.jpg";
import footerimg6 from "../../assets/footer/gallery-thumb-6.jpg";
import footerimg7 from "../../assets/footer/gallery-thumb-7.jpg";
import footerimg8 from "../../assets/footer/gallery-thumb-8.jpg";
import footerimg9 from "../../assets/footer-image.png";
import WhatWeDo from "../../pages/WhatWeDo";

const Footer = () => {

  return (
    <Fragment>


      <footer style={{ background: "#061138", color: "#868da5"}} className="footerdibnmain fadeInLeft" >
        <div className="container-fluid foterPhone" style={{ padding: "0px 15px", maxWidth: "1200px" }}>
          <div className="container" >
            <div class="widgets-inner">
              <div class="shape-1"></div>
              <div class="shape-2"></div>
              <div class="left-image">
              
                <img src={footerimg9} alt="" title="" />
              </div>
              <div class="offer-carousel-box">

                <WhatWeDo />
              </div>
              <div className="row" style={{ position: "relative", padding: "0px 0px 90px" }}>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="footerH">
                    
                <h4 className="footerHeader">CONTACT DETAILS</h4>
                  </div>
                  {/* <img className="elementor-animation-buzz" src={footerLogo} style={{ height: "50px", marginBottom: "30px" }} alt="webiste_logo" /> */}
                  <div className="info">
                    <ul>
                      <li>
                        <FaMapMarker style={{ color: "#d5ae82", position: "absolute", marginLeft: "-25px" }} />Sailing <br></br>26, Old Brozon Mall, Newyrok <br></br>NY 10005</li>
                      <li>
                        <FaPhoneAlt style={{ color: "#d5ae82", position: "absolute", marginLeft: "-25px" }} />
                        Call us <a href="tel:555626-0234" className="phoneNo">555 626-0234</a></li>
                      <li>
                        <FaMailBulk style={{ color: "#d5ae82", position: "absolute", marginLeft: "-25px" }} />
                        <a href="mailto:support@sailing.com" className="phoneNo">support@sailing.com</a>
                      </li>
                    </ul>
                  </div>
                  <div className="logoSocial">
                    <ul>
                      <li>
                        <div style={{ paddingTop: "24px" }}>
                          <span className="servicesIcon">
                            <FaFacebook className="innovationIconA" />
                          </span>
                        </div>
                      </li>
                      <li>
                        <div style={{ paddingTop: "24px" }}>
                          <span className="servicesIcon">
                            <FaTwitter className="innovationIconA" />
                          </span>
                        </div>
                      </li>
                      <li>
                        <div style={{ paddingTop: "24px" }}>
                          <span className="servicesIcon">
                            <FaYoutube className="innovationIconA" />
                          </span>
                        </div>
                      </li>
                      <li>
                        <div style={{ paddingTop: "24px" }}>
                          <span className="servicesIcon">
                            <FaWifi className="innovationIconA" />
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div className="footerH">
                    <h4 className="footerHeader">WHAT WE DO</h4>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <ul className="links">
                        <li><a href="#">Destination</a></li>
                        <li><a href="#">Package</a></li>
                        <li><a href="#">Top Attractions</a></li>
                        <li><a href="#">Insurance</a></li>
                        <li><a href="#">Essential Tips</a></li>
                        <li><a href="#">Buy &amp; Sell</a></li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <ul className="links">
                        <li><a href="#">About Us</a></li>
                        <li><a href="#">Team</a></li>
                        <li><a href="#">Updates</a></li>
                        <li><a href="#">Testimonials</a></li>
                        <li><a href="#">Contact</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
              <div className="footerH">
                  <h4 className="footerHeader">INSTAGRAM</h4>
                </div>
                <div>
                <div className="footerImage">
                  <img src={footerimg1} alt="footer_img"></img>
                 
                </div>
                <div className="footerImage">
                  <img src={footerimg2} alt="footer_img"></img>
                </div>
                <div className="footerImage">
                  <img src={footerimg3} alt="footer_img"></img>
                </div>
                <div className="footerImage">
                  <img src={footerimg4} alt="footer_img"></img>
                </div>
                <div className="footerImage">
                  <img src={footerimg5} alt="footer_img"></img>
                </div>
                <div className="footerImage">
                  <img src={footerimg6} alt="footer_img"></img>
                </div>
                <div className="footerImage">
                  <img src={footerimg7} alt="footer_img"></img>
                </div>
                <div className="footerImage">
                  <img src={footerimg8} alt="footer_img"></img>
                </div>
                
              </div>
              </div> */}




              </div>

            </div>
          </div>


        </div>
        {/* <div className="copyrightd">
          <div className="container" style={{ padding: "0px 15px", maxWidth: "1200px" }} >
            <div className="clearfix">
              <div>
                <p className="copyrightData">Copyright ©2020 Sailing. All Rights Reserved.</p>
              </div>
              <div>
                <p className="copyTerms">Terms Of Use & Private Policy</p>
              </div>
            </div>
          </div>
        </div> */}
        <div class="footer-bottom">
          <div class="auto-container">
            <div class="inner clearfix">
              <div class="copyright">Copyright © 2020 Sailing. All Rights Reserved.</div>
              <div class="bottom-links"><a href="#">Terms of Use</a> &amp; <a href="#">Private Policy</a></div>
            </div>
          </div>
        </div>
      </footer>


    </Fragment>
  );
};

export default Footer;
